window._loadDoneCalled = false;
window._footerInitialized = false;
window._datesInitialized = false;
window._loaded = false;
window.DDMMYY = "D.M.YYYY";
window.DDMMYYYY = "DD.MM.YYYY";

window.today = moment().startOf('day');
window.thisMonday = moment().startOf('isoweek');
window.thisSaturday = nextWeekday(6);
window.nextSaturday = moment(thisSaturday).add(1, 'w');
window.thisSunday = nextWeekday(7);
window.nextSunday = moment(thisSunday).add(1, 'w');
window.nextMonday = moment(thisMonday).day(8);

window.formatString = "D MMMM";
window.dateFormatDefault = 'D MMMM';
window.dateFormatXS = 'D.MM.';
window.dateFormatS = 'ddd, D. MMM';
window.dateFormatM = 'dddd, Do MMM';
window.dateFormatL = 'dddd, Do MMMM';

window.pdfViewerParams = "?toolbar=1";

window.sliderInitialized = false;
window.datesInitialized = false;

import "bootstrap-table";
import "bootstrap";
import Isotope from 'isotope-layout';

import "slick-carousel";

window.bootstrap = require('bootstrap');

// resources/js/main.js
import './ayb.module.js'; // Lädt und initialisiert das Modul

// Deine eigenen AngularJS-Komponenten, Services, etc.
import '../../public/angularjs/constants.js';
import '../../public/angularjs/helpers.js';
import '../../public/angularjs/app.module.js';
import '../../public/angularjs/factories/teacher.factory.js';
import '../../public/angularjs/services/teacher.service.js';
import '../../public/angularjs/helpers.js';
import '../../public/angularjs/runBlock.js';
import '../../public/angularjs/controllers.js';
import '../../public/angularjs/components.js';

import "../../public/angularjs/core/core.module.js";
import "../../public/angularjs/core/card/card.module.js";
import "../../public/angularjs/core/card/card.component.js";
import "../../public/angularjs/core/alert/alert.component.js";
import "../../public/angularjs/core/date/date.module.js";
import "../../public/angularjs/core/date/date.component.js";
import "../../public/angularjs/components/gift-vouchers/gift-vouchers.component.js";
import "../../public/angularjs/components/components.module.js";
import "../../public/angularjs/components/teacher-profile/teacher-profile.module.js";
import "../../public/angularjs/components/teacher-profile/teacher-profile.component.js";
import "../../public/angularjs/components/location/location.module.js";
import "../../public/angularjs/components/location/location.component.js";
