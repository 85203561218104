ayb.service('TeacherService', function($http, $rootScope, TeacherFactory) {

    let self = this;
    self.teacherPromise = null;
    self.teacherRequest = null;

    /**
     * Lädt alle Lehrer, falls noch nicht geschehen, und gibt ein Promise zurück,
     * das ein Array aller Lehrer resolved.
     */
    this.allTeachers = function() {
        // Wenn schon Lehrer im RootScope geladen wurden, direkt zurückgeben
        if ($rootScope.teachers) {
            return Promise.resolve($rootScope.teachers);
        }

        // Falls noch keine Anfrage gestartet wurde, holen wir die Lehrer-Daten
        if (!self.teacherRequest) {
            self.teacherRequest = $http.get('/angularjs/data/teachers.json', {
                cache: true
            });
        }

        return self.teacherRequest.then(jsonTeachers => {
            // Wenn noch keine Fitogram-Teacher geladen, dann jetzt laden
            if (!self.teacherPromise) {
                self.teacherPromise = $http.get('/fg-cache/fito-teachers.json', {
                    cache: true
                });
            }

            return self.teacherPromise.then(response => {
                let teachers = response.data;
                for (let i = teachers.length - 1; i >= 0; i--) {
                    const teacherData = teachers[i];
                    const fitoTeacher = TeacherFactory.build(teacherData);

                    const jsonTeacher = findObjectByKey(jsonTeachers.data, "trainerId", teacherData.trainerId);
                    if (jsonTeacher) {
                        if (jsonTeacher.hiddenFromTeamList) {
                            // Lehrer nicht im Team anzeigen
                            teachers.splice(i, 1);
                            continue;
                        }
                        // Übernehme zusätzliche Eigenschaften
                        for (const key in jsonTeacher) {
                            fitoTeacher[key] = jsonTeacher[key];
                        }
                    }

                    teachers[i] = fitoTeacher;
                }

                $rootScope.teachers = teachers;
                $rootScope.allTeachersLoaded = true;
                return $rootScope.teachers;
            });
        });
    };

    /**
     * Sucht nach einem Lehrer mit einer bestimmten ID. Ist noch kein Lehrer geladen,
     * werden allTeachers() aufgerufen.
     */
    this.teacherWithId = function(id) {
        if ($rootScope.teachers) {
            return Promise.resolve(findObjectByKey($rootScope.teachers, "id", id));
        }
        return this.allTeachers().then(teacherList => {
            return findObjectByKey(teacherList, "id", id);
        });
    };
});
