// resources/js/ayb.module.js
import angular from 'angular';
import 'angular-sanitize';
import 'angular-filter';

const ayb = angular.module('ayb', [
  'ngSanitize',
  'angular.filter',
  'components',
  'core',
  'elif'
]);

ayb.config(['$interpolateProvider', function($interpolateProvider) {
  $interpolateProvider.startSymbol('[[');
  $interpolateProvider.endSymbol(']]');
}]);

// Optional: global verfügbar machen
window.ayb = ayb;

export default ayb;
