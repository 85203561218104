angular.
  module('core').
  component('alert', {	
 	bindings: {
      tit: '<',
      titDe: '<',
      titEn: '<',            
	  id: '<',
	  type: '@'
	},
	transclude: { 
		tit : '?tit',
		content: '?content'
	},	
	templateUrl: '/angularjs/core/alert/alert.template.html',	
	controller: function Controller($transclude) {
        var self = this;
    	self.hasTitle = function() {
      		return $transclude.isSlotFilled('tit') || self.tit != null || self.titDe != null || self.titEn != null ;
		};
    	self.alertType = function() {
      		return self.type ? self.type : 'warning';
    	};
		
		
	}	

});