/**
 *
 * @ngdoc module
 * @name core
 *
 * @requires components.contact
 * @requires components.auth
 *
 * @description
 *
 * This is the components module. It includes all of our components.
 *
 **/

angular
  .module('core', 
    [
    'card', 
	  'date'
    ]
  );