/**
 * Components
 */
ayb.component('timeSpan', {
    bindings: {
        start: '<',
        end: '<'
    },
    template: "<span ng-if = '$root.de'>[[ $ctrl.start ]] - [[ $ctrl.end ]]</span><span ng-if = '$root.en'>[[ $ctrl.start ]] - [[ $ctrl.end ]]</span>"

});

ayb.component('tel', {
    template: '<a href = "tel:+493048496665"><i class="fa-solid fa-phone"></i> +49.30.48496665</a>'
});

ayb.component('email', {
    bindings: {
        text: '<'
    },
    template: '<a href = "mailto:info@ashtangaberlin.com"><i class="fa-solid fa-envelope"></i> [[ $ctrl.text ? $ctrl.text : "e-mail" ]]</a>'
});

ayb.component('headTags', {
    templateUrl: '/angularjs/core/head-tags/head-tags.template.html'
});

ayb.component('teacherTable', {
    templateUrl: '/angularjs/components/teacher-table/teacher-table.template.html',

});

ayb.component('schedule', {
    templateUrl: '/angularjs/pages/schedule/schedule.template.html'
});

ayb.component('onlineTalksOverview', {
    templateUrl: '/angularjs/components/online-talks-overview/online-talks-overview.template.html'
});

ayb.component('retreatsOverview', {
    templateUrl: '/angularjs/components/retreats-overview/retreats-overview.template.html'
});


ayb.component('pricingOffer', {
    templateUrl: '/angularjs/components/pricing-offer/pricing-offer.template.html'
});

ayb.component('spinner', {
    template: "<div class='spinner d-flex justify-content-center'><div class='spinner-border' role='status'><span class='visually-hidden'>Loading...</span></div></div>"
});

ayb.directive('modalButton', [function() {
    return {
        transclude: {
            'deSlot': '?de',
            'enSlot': '?en'
        },
        templateUrl: '/angularjs/core/modal-button/modal-button.template.html',
        replace: false,
        scope: {
            targetId: '@',
            clazz: '@',
            contentHref: '@'
        }
    };
}]);


ayb.directive('modal', [function() {
    return {
        transclude: {
            'headerSlot': '?mhead',
            'bodySlot': '?mbody',
            'footerSlot': '?mfooter'
        },
        templateUrl: '/angularjs/core/modal/modal.template.html',
        replace: true,
        controller: ['$scope', '$transclude', function ModalController($scope, $transclude) {
            $scope.hasHeader = function(pane) {
                return $transclude.isSlotFilled('hasHeader');
            };
            $scope.hasFooter = function(pane) {
                return $transclude.isSlotFilled('hasFooter');
            };
            $scope.afterRender = function() {
                var urlParams = new URLSearchParams(window.location.search);

                if (urlParams.has('showModalWithId')) {
                    let modalId = '#' + urlParams.get('showModalWithId'); // "edit"
                    $(modalId).modal('show');
                    var myModalEl = document.querySelector(modalId);
                    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl)
                }
            }
        }],
        scope: {
            targetId: '@'
        }
    };
}]);

ayb.component('bookingCalendar', {
    templateUrl: '/angularjs/components/booking-calendar/booking-calendar.template.html'
});

ayb.component('eversportsPricingWidget', {
    templateUrl: '/angularjs/components/eversports-pricing-widget/eversports-pricing-widget.template.html',
    bindings: {
        widgetId: '<'
    },
    controller: function($scope) {
        var self = this;
        $scope.initWidget = function() {
            var widgetUrl = "https://widget-static.eversports.io/loader.js";
            /** Funktionen sind in scripts.js */

            let selector ='[data-eversports-widget-id="'+self.widgetId+'"] div';
            loadJS(selector,
                widgetUrl,
                function() {
                /** Hacking der Darstellung des Eversports Widgets, in dem alles in #shadow-root versteckt ist */
                const shadowRoot = document.querySelector("[data-eversports-widget-id] div").shadowRoot;
                loadCSSIntoShadow(shadowRoot, eversportsCssHack); // Replace 'styles.css' with your CSS file path
            }

        );


        };

    }
});


ayb.component('de', {
    transclude: true,
    template: '<ng-if ng-if ="$root.isGerman"><ng-transclude></ng-transclude></ng-if>'
});
ayb.component('en', {
    transclude: true,
    template: '<ng-if ng-if ="$root.isEnglish"><ng-transclude></ng-transclude></ng-if>'
});

ayb.component('priceDisplay', {
    bindings: {
        regular: '<',
        earlyBird: '<',
        isEarlyBird: '<',
        endEarlyBird: '<',
        regInEarlyBird: '<?'
    },
    template: '<span style = "white-space:nowrap"><span ng-if = "$ctrl.regInEarlyBird || !$ctrl.isEarlyBird" ng-class="{regularInEarlyBirdPeriodPrice: $ctrl.isEarlyBird, effectivePrice:!$ctrl.isEarlyBird}">[[ $ctrl.regular ]]</span> <span ng-if = "$ctrl.isEarlyBird" class = "earlyBirdPrice animate-if" ng-class="{effectivePrice:$ctrl.isEarlyBird}" title = "Early Bird Price!">[[ $ctrl.earlyBird ]]</span> &euro;</span>',
    controller: function() {
        var self = this;
        self.$onInit = function() {
            if (self.isEarlyBird === undefined && self.endEarlyBird !== undefined) {
                self.isEarlyBird = isEarlyBird(self.endEarlyBird);
            }
            if (self.regInEarlyBird === undefined) {
                self.regInEarlyBird = true;
            }
        }
    }
});


ayb.component('nextGwsDate', {
    bindings: {
        format: '<',
        f: '<'
    },
    template: '<ng-if ng-if = "!$root.nextGws"><a href = "mailto:info@ashtangaberlin.com"><local><en>Contact us for the next date</en><de>Kontaktiere uns für den nächsten Termin</de></local></a></ng-if><ng-if ng-if = "$root.nextGws"><date ng-if = "$root.nextGws.cancellation != $root.nextGws.startDate" value="$root.nextGws.startDate" f = "$ctrl.f" format = "$ctrl.format"></date><span ng-if = "$root.nextGws.cancellation == $root.nextGws.startDate"><date style = "text-decoration:line-through" value = "$root.nextGws.startDate"></date></ng-if>',
    controller: function() {
        var self = this;
        self.$onInit = function() {
            if (!self.f) {
                self.f = "s";
            }
        }
    }


});

ayb.component('local', {
    bindings: {
        de: '<',
        en: '<',
        clazz: '@',
        style: '@'
    },
    transclude: {
        'deSlot': '?de',
        'enSlot': '?en'
    },
    templateUrl: '/angularjs/core/local/local.template.html'
});

ayb.component('nextMoonDate', {
    bindings: {
        format: '<',
        f: '<'
    },
    template: '<date value = "$root.nextMoonDateObject.startDate" f = "$ctrl.f" format = "$ctrl.format"></date>'
});


ayb.component('nextMoonTimeSpan', {
    template: '<span class = "dateRange"><ng-bind-html ng-bind-html = "$root.nextMoonDateObject.timeDescription"></ng-bind-html></span>',
    controller: function($rootScope) {
        var self = this;
        self.$onInit = function() {}
    }
});



ayb.component('info', {
    bindings: {
        content: '<',
        title: '<'
    },
    template: '<span class = "infoPopover" data-trigger="hover" data-toggle="popover" data-title="[[ $ctrl.title ]]" data-content="[[ $ctrl.content ]]"><i class = "fa fa-info"></i> <ng-transclude></ng-transclude></span>',
    transclude: true
});


ayb.component('pricing', {
    templateUrl: '/angularjs/components/pricing/pricing.template.html',
    bindings: {},
    controller: function($rootScope) {
        var self = this;

        self.showSocialTariffs = false;
        self.incomeLevelOptions = {
            zero: {
                id: "zero",
                nameEN: "very low",
                nameDE: "sehr gering",
                descriptionDE: "Ermäßigung 50% auf Mitgliedschaften.",
                descriptionEN: "",
                voucherCode: "LOW-INCOME-50",
                incomeRange: "<800€"
            },
            low: {
                id: "low",
                nameEN: "small",
                nameDE: "gering",
                descriptionDE: "Dein Einkommen ist ungefähr so hoch wie unseres. Wenn nötig unterstützen wir Dich mit einer Ermäßigung von 25%.",
                descriptionEN: "",
                voucherCode: "LOW-INCOME-25",
                incomeRange: "800€ - 1500"
            },
            normal: {
                id: "normal",
                nameEN: "average",
                nameDE: "mittel",
                descriptionDE: "Dein Einkommen ist höher als unseres. Bitte zahle den regulären Beitrag. Gelegentliche Spenden helfen uns sehr.",
                incomeRange: "1500€ - 3000€"

            },
            high: {
                id: "high",
                nameEN: "high",
                nameDE: "hoch",
                descriptionDE: "Dein Einkommen ist deutlich höher als unseres. Bitte überlege, zusätzlich eine stets kündbare Fördermitgliedschaft abzuschließen.",
                incomeRange: "3000€ - 6000€"
            },
            veryhigh: {
                id: "vhigh",
                nameEN: "very high",
                nameDE: "sehr hoch",
                descriptionDE: "Dein Einkommen ist sehr viel höher als unseres. Bitte schließe eine zusätzliche Fördermitgliedschaft ab, um unser Engagement für Geringverdiener zu unterstützen.",
                incomeRange: "6.000€ - "
            }

        }
        self.localizedIncomeLevelName = function(anIncomeLevel) {
            return $rootScope.isGerman ? anIncomeLevel.nameDE : anIncomeLevel.nameEN;
        }



        self.selectedIncomeLevel = self.incomeLevelOptions.normal;
        self.selecteProductTypesOptions = {
            name: 'visitor-pass'
        };

    }
});

ayb.component('newsletterSignup', {
    templateUrl: '/angularjs/components/newsletter-signup/newsletter-signup.template.html',
    bindings: {},
    controller: function($rootScope, $element) {
        var self = this;
        this.$postLink = function() {
            $element.append('<script type="text/javascript">var sib_prefix = "sib";var sib_dateformat = "dd-mm-yyyy";</script>');
            $element.append('<script type="text/javascript" src="https://my.sendinblue.com/public/theme/version4/assets/js/src/subscribe-validate.js?v=1635924718"></script>');
        };
    }

});

ayb.component('newsletterCard', {
    templateUrl: '/angularjs/components/newsletter-card/newsletter-card.template.html'
});

ayb.component('newsletterSignupModalLink', {
    templateUrl: '/angularjs/components/newsletter-signup-modal-link/newsletter-signup-modal-link.template.html',
    bindings: {
        btnClass: '@'
    },

});



ayb.component('cancellationTerms', {
    templateUrl: '/angularjs/components/cancellation-terms/cancellation-terms.template.html',
    transclude: true,
    bindings: {}
});

ayb.component('signupGutschein', {
    templateUrl: '/Library/SignupFormGutschein.lbi'
});

ayb.component('flowScheduleChanges', {
    templateUrl: '/Library/FlowClassScheduleChange.lbi',
    bindings: {}
});

ayb.component('mcSignupForm', {
    templateUrl: '/Library/McSignupForm.lbi',
    bindings: {}
});


ayb.component('teacherAvatar', {
    templateUrl: '/angularjs/components/teacher-avatar/teacher-avatar.template.html',
    bindings: {
        teacher: '<',
        cssClass: '<',
        cssStyle: '<'
    },
    controller: function($rootScope) {
        var self = this;
        self.$onInit = function() {
            console.log("Init teacherAvatar");
        }
        self.clazz = function() {
            var cls = "avatar ";
            if (this.cssClass !== undefined && this.cssClass !== null) {
                cls = "avatar " + this.cssClass;
            }
            return cls;
        }
    }

});
ayb.component('signupLink', {
    /*
     * template: '<a class = "[[ $ctrl.class ]] SignupLink cboxElement button"
     * style = "[[ $ctrl.style ]]" href = "#SignupForm" >{{$root.en &&
     * $ctrl.titleEn ? $ctrl.titleEn : $ctrl.title}}</a>',
     */
    templateUrl: '/angularjs/components/signup-link/signup-link.template.html',
    bindings: {
        title: '<',
        titleEn: '<',
        class: '<',
        style: '<',
        formId: '<',
        userSelections: '<'
    },
    controller: function($scope, $rootScope, $mdDialog, $rootElement) {
        var self = this;
        self.$onInit = function() {
            console.log("Init signupLink");

        }

        self.showEventRegistrationDialog = function(ev) {
            $mdDialog.show({
                    contentElement: '#' + self.formId,
                    parent: $rootElement,
                    /*
                     * template: 'Masdfs dfas fdslk fjsdaf alsdk', parent:
                     * angular.element(document.body),
                     */
                    clickOutsideToClose: true,
                    fullscreen: true,
                    onComplete: afterShowAnimation
                })
                .then(function(answer) {
                    self.status = 'You said the information was "' + answer + '".';
                }, function() {
                    self.status = 'You cancelled the dialog.';
                });

            function afterShowAnimation(scope, element, options) {
                // $rootScope.$broadcast('md-resize-textarea');
            }


        };
    }
});

/**
 * Dieser Controller wird
 */
ayb.controller('EventRegistrationDialogController', ['$scope', '$mdDialog', function($scope, $mdDialog) {
    self.$onInit = function() {
        console.log("Init DialogController");
    }
    $scope.hide = function() {
        $mdDialog.hide();
    };

    $scope.cancel = function() {
        $mdDialog.cancel();
    };

    $scope.answer = function(answer) {
        $mdDialog.hide(answer);
    };
}]);



ayb.directive('afterRender', ['$timeout', function($timeout) {
    var def = {
        restrict: 'A',
        transclude: false,
        link: function(scope, element, attrs) {
            $timeout(scope.$eval(attrs.afterRender), 0); // Calling a
            // scoped method
        }
    };
    return def;
}]);


ayb.component('gallery', {
    bindings: {
        id: '<',
        shuffle: '<',
        clazz: '<',
        style: '<'
    },
    templateUrl: '/angularjs/core/gallery/gallery.template.html',
    controller: function Controller($scope) {
        var ctrl = this;
        this.anzImgFunc = function() {
            switch (ctrl.id) {
                case 'retreat':
                    ctrl.anzImg = 7;
                    break;
                case 'ti1':
                    ctrl.anzImg = 21;
                    break;
                case 'ti2':
                    ctrl.anzImg = 0;
                    break;
                case 'scribbles':
                    ctrl.anzImg = 8;
                    break;
                case 'streklhof':
                    ctrl.anzImg = 22;
                    break;
                case 'practice':
                    ctrl.anzImg = 0;
                    break;
                case 'breitenteicher_muehle':
                    ctrl.anzImg = 19;
                    break;
                case 'shala':
                    ctrl.anzImg = 10;
                    break;
                case 'workbook':
                    ctrl.anzImg = 11;
                    break;
            }
            return ctrl.anzImg;
        }
        this.galleryFolderFunc = function() {
            if (ctrl.galleryFolder === undefined) {
                ctrl.galleryFolder = "/bilder/Galleries/" + ctrl.id + "/";
            }
            return ctrl.galleryFolder;
        }

        this.filePrefixFunc = function() {
            if (ctrl.filePrefix === undefined) {
                ctrl.filePrefix = ctrl.id + "_";
            }
            return ctrl.filePrefix;
        }
    }
});





ayb.component('bildungspraemieDescription', {
    bindings: {
        selectedModule: '<'
    },
    templateUrl: '/angularjs/components/bildungspraemie-description/bildungspraemie-description.template.html'
});

ayb.component('bildungsurlaubDescription', {
    bindings: {
        selectedModule: '<'
    },
    templateUrl: '/angularjs/components/bildungsurlaub-description/bildungsurlaub-description.template.html'
});


ayb.component('slick', {
    bindings: {
        anzImages: '<',
        galleryPath: '<',
        filePrefix: '<',
        images: '<',
        id: '<',
        clazz: '<',
        style: '<',
        shuffle: '<',
        index: '='
    },
    transclude: true,
    templateUrl: '/angularjs/core/slick/slick.template.html',
    controller: function Controller($scope) {
        var self = this;
        self.$onInit = function() {
            if (self.shuffle === undefined) {
                self.shuffle = true;
            }
            if (self.images === undefined) {
                self.images = self.imageArray(self.anzImages, self.galleryPath, self.filePrefix);
            }
            if (self.shuffle) {
                self.images = shuffle(self.images);
            }
        }
        self.imageArray = function imageArray(anzImages, galleryPath, filePrefix) {
            this.images = [anzImages];
            for (let i = 0; i < anzImages; i++) {
                var counter = i + 1;
                var number = ("0" + counter).slice(-2);
                this.images[i] = {
                    imgSrc: galleryPath + filePrefix + number + ".jpg"
                };
            }
            return this.images;
        }

        // Called because of the after-render directive
        $scope.initializeSlick = function() {

            // NOCH NICHT GETESTET, SEIT AUS scripts.js raus
            var sliders = $(".SlickStandard");

            sliders.each(function() {
                var aSlider = $(this);
                var adaptiveHeight = aSlider.data("adaptiveHeight");
                if (!adaptiveHeight) {
                    adaptiveHeight = true;
                }
                var dots = aSlider.data("dots");
                if (!dots) {
                    dots = false;
                }
                aSlider.slick({
                    autoplay: true,
                    adaptiveHeight: false,
                    fade: true,
                    cssEase: 'ease-out',
                    arrows: true,
                    useTransform: true,
                    lazyLoad: 'ondemand',
                    autoplaySpeed: 2000,
                    dots: dots
                }).show();
            });
        }
    }
});


ayb.component('gsA', {
    bindings: {
        href: '<',
        hrefEn: '<',
        disabled: '<',
        target: '<'
    },
    transclude: {
        'deSlot': '?de',
        'enSlot': '?en'
    },
    templateUrl: '/angularjs/components/gs-a/gs-a.template.html',
    controller: function($rootScope) {
        var self = this;
        self.localizedHref = function() {
            if ($rootScope.isEnglish && self.hrefEn !== undefined) {
                return self.hrefEn;
            }
            return self.href;
        }
    }
});

ayb.component('travelling', {
    templateUrl: '/angularjs/components/travelling/travelling.template.html',
    transclude: true
});

ayb.component('specialOffer', {
    bindings: {
        de: '<',
        en: '<',
        title: '<',
        titleEn: '<'
    },
    transclude: {
        'deSlot': '?de',
        'enSlot': '?en',
        'deTitleSlot': '?titleDe',
        'enTitleSlot': '?titleEn',
        'titSlot': '?tit'
    },
    templateUrl: '/angularjs/components/special-offer/special-offer.template.html',
    controller: function($transclude, $scope) {
        this.uniqueId = $scope.$id;
        this.contentVisible = false;
        this.hasEnTitleTransclude = function() {
            return $transclude.isSlotFilled('slot');
        };
        this.hasDeTitleTransclude = function() {
            return $transclude.isSlotFilled('slot');
        };
        this.toggleReadMore = function() {
            this.contentVisible = !this.contentVisible;
        }
    }
});

ayb.component('grundlagenworkshop', {
    templateUrl: '/angularjs/components/grundlagenworkshop/grundlagenworkshop.template.html',
    bindings: {
        workshop: '<'
    },
    controller: function($rootScope, $scope) {
        var self = this;
        self.$onInit = function() {
            console.log('onInit of grundlagenworkshop');
        };
    }

});

ayb.component('workshopPricing', {
    bindings: {
        workshop: '<'
    },
    transclude: {
        'reducedRateDescriptionSlot': '?reducedRateDescription'
    },
    templateUrl: '/angularjs/components/workshop-pricing/workshop-pricing.template.html',
    controller: function($rootScope, $window) {
        var self = this;
        self.isMember = false;
        self.modulesHaveDays = false;

        self.$onInit = function() {
            // here you can do a check for your self.layout and set a value if
            // there is none
            console.log('onInit of modulesPricing');
        };
        self.isEarlyBird = function() {
            if (self.workshop == null) {
                return false;
            }
            return self.workshop.isInEarlyBird();
        }

        self.zeigeTotal = function(module) {
            return self.isMember;
        }

        self.workshopName = function() {
            if (self.workshop !== undefined) {
                if ($rootScope.isEnglish && self.workshop.titleEn !== undefined && self.workshop.titleEn.length > 0) {
                    return self.workshop.titleEn;
                }
                return self.workshop.title;
            }
        }

        self.bookingLink = function(module) {
            if (module !== undefined) {
                if (self.isMember && module.bookingLinkMember) {
                    return module.bookingLinkMember;
                }
                if (module.priceSpecial && module.bookingLinkSpecialPrice) {
                    return module.bookingLinkSpecialPrice;
                }
                return module.bookingLink;
            }
            return null;
        }

        self.bookingLinkTarget = function(module) {
            if (module !== undefined) {
                var bookingLink = this.bookingLink(module);
                if (bookingLink && bookingLink.indexOf('http') == 0) {
                    return '_new';
                }
                return "";
            }
            return null;
        }



        self.setConversionParams = function(module) {

            $window.dataLayer = $window.dataLayer || []
            dataLayer.push({
                'workshopId': this.workshop.id,
                'moduleId': module.id,
                'total': module.totalPrice(self.isMember)
            });
        }
    }
});

ayb.component('gsWorkshopSchedule', {
    bindings: {
        workshop: '<'
    },
    templateUrl: '/angularjs/components/workshop-schedule/workshop-schedule.template.html',
    controller: function($rootScope) {
        var self = this;
        self.$onInit = function() {
            // here you can do a check for your self.layout and set a value if
            // there is none

        }

        self.description = function(session) {
            if ($rootScope.isEnglish && session.descriptionEn) {
                return session.descriptionEn;
            } else {
                return session.description;
            }
        }

        self.title = function(session) {
            if ($rootScope.isEnglish && session.titleEn) {
                return session.titleEn;
            } else {
                return session.title;
            }
        }
    }
});

ayb.component('schedules', {
    templateUrl: '/angularjs/components/schedules/schedules.template.html',
    controller: function($scope) {

        this.$onInit = function() {
            var basedir = "/schedules/";

            /*
            var weeknumber = (moment().isoWeek()).pad(2);
            let url_base = basedir + moment().isoWeekYear() + "/Schedule_"+moment().year()+"_kw_";
            var urlCurrent =  url_base + weeknumber;
            var urlCurrentPdf = urlCurrent + ".pdf";
            var urlCurrentJpg = urlCurrent + ".jpg";
            var currentWeekSchedule = {
                "from": thisMonday.locale(lang).format(formatString),
                "until": thisSunday.locale(lang).format(formatString),
                "pdfFileHref": urlCurrentPdf,
                "pdfFileInlineHref": urlCurrentPdf + pdfViewerParams,
                "imgSrc": urlCurrentJpg
            };
            var nextWeekMoment = moment().isoWeek(moment().isoWeek() + 1);
            weeknumber = (nextWeekMoment.isoWeek()).pad(2);
            var urlNext = url_base + weeknumber;
            var urlNextPdf = urlNext + ".pdf";
            var urlNextJpg = urlNext + ".jpg";
            var nextWeekSchedule = {
                "from": nextMonday.locale(lang).format(formatString),
                "until": nextSunday.locale(lang).format(formatString),
                "pdfFileHref": urlNextPdf,
                "pdfFileInlineHref": urlNextPdf + pdfViewerParams,
                "imgSrc": urlNextJpg
            };

            if (UrlExists(urlCurrentJpg)) {
                list.push(currentWeekSchedule)
            }
            if (UrlExists(urlNextJpg)) {
                list.push(nextWeekSchedule)
            }

            */
        };


    }


});

ayb.component('bildungspraemieStern', {
    bindings: {
        workshop: '<'
    },
    templateUrl: '/Library/bildungspraemieStern.lbi',
    controller: function() {
        var self = this;
        self.$onInit = function() {
            // here you can do a check for your self.layout and set a value if
            // there is none

        }
    }
});

ayb.component('eventgroupEventTable', {
    bindings: {
        workshop: '<'
    },
    templateUrl: '/angularjs/components/eventgroup-event-table/eventgroup-event-table.template.html'
});

ayb.component('retreatTiContents', {
    bindings: {
        workshopController: '<',
        userSelections: '<',
        extraInfo: '<'
    },
    transclude: {
        alternativeEventSuggestionSlot: "?alternativeEventSuggestion"
    },
    templateUrl: '/angularjs/components/retreat-ti-contents/retreat-ti-contents.template.html',
    controller: function($transclude) {
        var self = this;

        self.$onInit = function() {}
    }
});

ayb.component('alternativeLangInfo', {
    bindings: {
        event: '<'
    },
    transclude: {
        alternativeEventSuggestionSlot: "?alternativeEventSuggestion"
    },
    templateUrl: '/angularjs/components/alternative-lang-info/alternative-lang-info.template.html',
    controller: function() {
        var self = this;
        self.$onInit = function() {
            // here you can do a check for your self.layout and set a value if
            // there is none

        }
    }
});

ayb.component('signupForm', {
    templateUrl: '/angularjs/components/signup-form/signup-form.template.html',
    bindings: {}
});

ayb.directive('refreshable', [function() {
    return {
        restrict: 'A',
        scope: {
            refresh: "=refreshable"
        },
        link: function(scope, element, attr) {
            var refreshMe = function() {
                element.attr('src', element.attr('src'));
            };

            scope.$watch('refresh', function(newVal, oldVal) {
                if (scope.refresh) {
                    scope.refresh = false;
                    refreshMe();
                }
            });
        }
    };
}])


ayb.component('navItem', {
    bindings: {
        href: '<',
        hrefEn: '<',
        title: '@',
        titleEn: '@',
        target: '<',
        id: '@',
        class: '<',
        linkClass: '<',
        disabled: '<',
        hidden: '<'
    },
    transclude: {
        'deSlot': '?de',
        'enSlot': '?en'
    },
    templateUrl: '/angularjs/components/nav-item/nav-item.template.html',
    controller: function($rootScope) {
        var self = this;
        self.localizedHref = function() {
            if ($rootScope.isEnglish && self.hrefEn !== undefined) {
                return self.hrefEn;
            }
            return self.href;
        }
        self.localizedTitle = function() {
            if ($rootScope.isEnglish && self.titleEn !== undefined) {
                return self.titleEn;
            }
            return self.title;
        }
        self.localizedTitle = function() {
            if ($rootScope.isEnglish && self.titleEn !== undefined) {
                return self.titleEn;
            }
            return self.title;
        }
    }
});
