ayb.controller('BodyController', ['$scope', function($scope) {
	$scope.afterRender = function () {
		console.log ("After Render in Body Controller Called");
	};
}]);

ayb.controller('SanskritAnalysisController', ['$scope', '$timeout', function($scope,$timeout) {
	$scope.showSanskritAnalysis = true;
}]);



ayb.filter('containsModuleWithId', function () {
  return function (items, moduleId) {
  	if (!moduleId) {
  		return items;
  	}
    var filtered = [];
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (item.modules.indexOf(moduleId) !== -1) {
        filtered.push(item);
      }
    }
    return filtered;
  };
});


ayb.controller('ArticleController', function ArticleController ($scope) {

});

ayb.controller('AsideController', function AsideController ($scope) {
});

ayb.controller('BannerController', function BannerController ($scope,$rootScope) {

});
