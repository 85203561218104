import * as Helpers from '../../angularjs/helpers.js';

ayb.factory('TeacherFactory', function($rootScope) {
    function Teacher(teacherData) {
        for (const key in teacherData) {
            this[key] = teacherData[key];
        }

        this.name = `${this.firstName} ${this.lastName}`;
        this.teacherList = 1;
        this.order = 0;
        this.avatar = this.imageUrl;

        if (this.descriptions) {
            if (!this.workshopTeachingDescriptionDE) {
                this.workshopTeachingDescriptionDE = Helpers.textFromDescriptionsDict(this.descriptions, 'DE');
            }
            if (!this.workshopTeachingDescriptionEN) {
                this.workshopTeachingDescriptionEN = Helpers.textFromDescriptionsDict(this.descriptions, 'EN');
            }
        } else {
            console.debug(`Lehrer ohne descriptions gefunden: ${this.name}`);
        }
    }

    Teacher.prototype.longDescriptionHtml = function() {
        if (!this._longDescriptionHtml) {
            this._longDescriptionHtml = {};
        }
        const currentLang = $rootScope.lang.toUpperCase();
        if (!this._longDescriptionHtml[currentLang]) {
            const text = Helpers.textFromDescriptionsDict(this.descriptions, currentLang);
            this._longDescriptionHtml[currentLang] = text;
        }
        return this._longDescriptionHtml[currentLang];
    };

    Teacher.prototype.avatarImgSrc = function() {
        let imgName = this.avatar || this.portrait;
        imgName = $rootScope.teacherPortraitRoot + imgName;
        return imgName;
    };

    Teacher.prototype.isGrischa = function() {
        return this.firstName === 'Grischa';
    };

    Teacher.prototype.nameDisplay = function() {
        return this.displayName || this.firstName;
    };

    Teacher.prototype.extraTitle = function() {
        return localizedValue($rootScope, this.extraTitleEN, this.extraTitleDE);
    };

    Teacher.prototype.description = function() {
        return localizedValue($rootScope, this.descriptionEN, this.descriptionDE);
    };

    Teacher.prototype.workshopTeachingDescription = function() {
        return localizedValue($rootScope, this.workshopTeachingDescriptionEN, this.workshopTeachingDescriptionDE);
    };

    Teacher.build = function(teacherData) {
        if (!teacherData) return null;
        return new Teacher(teacherData);
    };

    return Teacher;
});
