angular.
  module('card').directive('card', [function () {
    return {
		transclude: { 
			titSlot : '?tit',
			titDeSlot : '?titDe',
			titEnSlot : '?titEn',
			contentSlot: '?content',
			footerSlot: '?cardfooter',
			deContentSlot: '?contentDe',
			enContentSlot: '?contentEn'
		},			
        templateUrl: '/angularjs/core/card/card.template.html',
        replace: true,
        controller: ['$scope', '$transclude', function CardController($scope,$transclude,$rootScope) {	  
			$scope.collapseOpen = ($scope.initialOpen === null || $scope.initialOpen == undefined) ? true : $scope.initialOpen;
			$scope.hasTitle = function(pane) {
				if (!$scope._hasTitle) {
					$scope._hasTitle = $transclude.isSlotFilled('titSlot') || $transclude.isSlotFilled('titDeSlot') || $transclude.isSlotFilled('titEnSlot') || $scope.tit != null || $scope.titDe != null || $scope.titEn != null ;
				}
				return $scope._hasTitle;
			};
			$scope.hasFooter = function(pane) {
			    if (!$scope._hasFooter) {
			        $scope._hasFooter = $transclude.isSlotFilled('footerSlot');
			    }
			    return $scope._hasFooter;
			};
		  }],
        scope: {
			tit: '<',
			titDe: '<',
			titEn: '<',
			subTitle: '<',
			imageUrl: '<',
			imageAlt: '<',
			content: '<',
			id: '<',
			clazz: '<',
			style: '@',
			initialOpen: '<'
        }    
    };
}]);
