angular.
  module('teacherProfile').
  component('teacherProfile', {	
 	bindings: {
      teacherId: '<',
	  teacher: '<'
    },	
	controller: ["$rootScope", "TeacherService", "$q", function($rootScope,TeacherService, $q){
		var self = this;		

		self.$onInit = function() {
			if (self.teacherId!=null) {
			// here you can do a check for your self.layout and set a value if there is none
				TeacherService.teacherWithId(self.teacherId).then(function (teacherData) {
					self.teacher = teacherData;
				});
			} 
			else {
				$q.resolve(self.teacher).then(
					function(resolvedValue) {
						self.teacher = resolvedValue;
					}
				);
			}
		}
	}],
    templateUrl: '/angularjs/components/teacher-profile/teacher-profile.template.html'
});