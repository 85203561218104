ayb.run(runBlock);

runBlock.$inject = ['$rootScope', '$http', '$sce', '$q', '$location', 'PropsService'];

function runBlock($rootScope, $http, $sce, $q, $location,  PropsService) {

    $rootScope.location = $location;
    $rootScope.telegramLinkAybCommunity = "https://t.me/+5ADwfbkcfBY0NWMy";
    $rootScope.teacherPortraitRoot = "/bilder/Lehrer/";
    $rootScope.hasOnlineEvents = false;
    $rootScope.hasRetreatEvents = false;
    $rootScope.testMode = false;

    setTimeout(function() {

        PropsService.initialize().then(function() {
            window.allEventsLoaded = true;
            ultimateInit();
        });

    }, 1000);

    // Weitere Funktionen vom $rootScope wie searchParamsForLocation,
    // setTargetGroup, setLanguage etc. würden wir in separate Dateien auslagern
    // oder zumindest am Ende dieser Datei lassen.

    $rootScope.searchParamsForLocation = function(locationObject) {
        if (Object.keys(locationObject.search()).length > 0) {
            return locationObject.search();
        }
        return parseQueryStringToDictionary(locationObject.absUrl());
    }

    $rootScope.targetGroups = {
        beginner: "beginner",
        ashtangi: "ashtangi"
    };
    $rootScope.selectedTargetGroup = $rootScope.targetGroups.ashtangi;

    $rootScope.setTargetGroup = function(targetGroup) {
        $rootScope.selectedTargetGroup = targetGroup;
        $rootScope.$broadcast('TargetGroupUpdated');
    }

    $rootScope.initLanguageAndRedirectIfNecessary = function(languageParam) {

            window.lang = $('html').attr('lang');

        if (window.lang == "en") {
            window.alternateLang = "de";
        } else {
            window.alternateLang = "en";
        }
        $rootScope.lang = window.lang;
        $rootScope.alternateLang = window.alternateLang;
        $rootScope.isEnglish = window.lang == "en";
        $rootScope.en = window.lang == "en";
        $rootScope.isGerman = window.lang == "de";
        $rootScope.de = window.lang == "de";

        moment().locale(window.lang);

    }


    $rootScope.displayBreakingNews = function() {
        return this.props && this.props.displayBreakingNews;
    }

    $rootScope.allTeachersLoaded = false;
    let searchParams = $rootScope.searchParamsForLocation($location);


    // Die Sprache wird bereits im php gesetzt. Hier werden alle Werte entsprechend im root scope usw. gesetzt.
    $rootScope.initLanguageAndRedirectIfNecessary(window.lang);

    if ($location.$$host == "localhost") {
        $rootScope.testMode = true;
    }
    if (searchParams.testMode) {
        $rootScope.testMode = searchParams.testMode === 'true';
    }

    if ($rootScope.testMode) {
        $rootScope.zeigeSupportPage = true;
    }

    $rootScope.languageName = function(languageParam) {
        if (languageParam == 'en') {
            return this.lang == 'en' ? 'English' : 'Englisch';
        }
        return this.lang == 'en' ? 'German' : 'Deutsch';
    }

}


ayb.service('PropsService', function($rootScope, $http, TeacherService) {

    this.initialize = function() {
        TeacherService.allTeachers().then(function() {
            if ($rootScope.nextMoonDateObject) {
                TeacherService.teacherWithId($rootScope.nextMoonDateObject.teacherId).then(function(teacherData) {
                    $rootScope.nextMoonDateObject.teacher = teacherData;
                });
            }

        })
        return $http.get('/Site.json', {
            cache: true
        }).then(function(response) {
            $rootScope.props = response.data;


            $rootScope.nextTIDate = response.data.nextTIDate;
            $rootScope.nextTIWeekendDate = response.data.nextTIDate;
            $rootScope.nextRetreat = response.data.nextRetreat;

            if ($rootScope.testMode) {
                $rootScope.props.benutzeEversports = true;
            }

            $rootScope.$broadcast('propsLoaded');
            $rootScope.propsLoaded = true;


        });
    };
});

let EventModuleFactory = function($rootScope) {

    /* Constructor */
    function EventModule(p_event, p_jsonData) {
        let _this = this;
        this.event = p_event;
        this.jsonData = p_jsonData;

        for (var key in this.jsonData) {
            this[key] = this.jsonData[key];
        }
    }

    EventModule.prototype.memberDiscount = function() {
        if (this.priceMember) {
            return this.currentPrice() - this.priceMember;
        }
        if (this.memberDiscountAmount) {
            return this.memberDiscountAmount;
        }
        return null;
    }

    EventModule.prototype.priceSpecialMessage = function() {
        if (this.priceSpecialMessage) {
            return this.priceSpecialMessage;
        }
        if (this.event.priceSpecialMessage) {
            return this.event.priceSpecialMessage;
        }
        return null;
    }

    EventModule.prototype.currentPrice = function() {
        if (this.priceSpecial) {
            return this.priceSpecial;
        } else {
            return this.event.isInEarlyBird() ? this.priceEarlyBird : this.price;
        }
    }

    EventModule.prototype.totalPrice = function(isMember) {
        var result = this.price;
        if (this.event.isInEarlyBird()) {
            result = this.earlyBirdPrice;
        }
        if (this.priceSpecial) {
            result = this.priceSpecial;
        }

        if (isMember && this.memberDiscount()) {
            result = result - this.memberDiscount();
        }
        return result;
    }

    EventModule.build = function(event, moduleData) {
        if (!moduleData) {
            return null;
        }
        return new EventModule(event, moduleData);
    };

    /**
     * Return the constructor function
     */
    return EventModule;
}

const bannerTypeZeile = {
    "retreat": ["RET", "REAT"],
    "online": ["ONL", "INE"],
    "community": ["SAN-", "GHA"],
    "special": ["SPE-", "CIAL"],
    "led": ["led"],
    "concert": ["CON-", "CERT"],
    "talk": ["TA", "LK"]
};

(function() {
    var elif = angular.module('elif', []);

    // This is copied from AngularJS because it is not
    // part of the public interface.
    var getBlockElements = function(nodes) {
        if (!nodes || !nodes.length) {
            return $();
        }

        var startNode = nodes[0];
        var endNode = nodes[nodes.length - 1];

        if (startNode === endNode) {
            return $(startNode);
        }

        var element = startNode;
        var elements = [element];

        do {
            element = element.nextSibling;
            if (!element) {
                break;
            }
            elements.push(element);
        } while (element !== endNode);

        return $(elements);
    };

    elif.factory('elif', [function() {
        // By requiring the scope have it's own copy of `elif.conditionals`
        // we avoid if/else-if/else structures that span AngularJS scopes.
        var getConditionals = function(scope) {
            if (angular.hasOwnProperty.call(scope, 'elif.conditionals')) {
                var conditionals = scope['elif.conditionals'];
                return conditionals[conditionals.length - 1];
            }
        };

        return {
            create: function(scope, fn, callback) {
                var conditionals = [{
                    fn: fn,
                    callback: callback || angular.identity
                }];
                var conditionalValues = [];

                scope.$watch(function() {
                    // Watch the boolean conditionals; we only need
                    // to run through the if/else-if/else chain if one
                    // of them changes.
                    conditionalValues.length = conditionals.length;
                    for (var i = 0, len = conditionals.length; i < len; i++) {
                        conditionalValues[i] = !!conditionals[i].fn();
                    }
                    return conditionalValues;
                }, function(conditionalValues) {
                    // Find first matching if/else-if.
                    var index = -1;
                    for (var i = 0, len = conditionals.length; i < len; i++) {
                        if (conditionalValues[i]) {
                            conditionals[i].callback(true);
                            index = i;
                            i++;
                            break;
                        } else {
                            conditionals[i].callback(false);
                        }
                    }

                    // Mark the rest of the else-ifs as not matched.
                    for (; i < len; i++) {
                        conditionals[i].callback(false);
                    }

                    // Handle else, if there is one.
                    conditionals.fallthrough && conditionals.fallthrough(index === -1);

                    return index;
                }, true);
                // Deep watch; we know that it is a simple list of booleans.

                // Keep track of if/else-if/else structures per AngularJS scope.
                if (!angular.hasOwnProperty.call(scope, 'elif.conditionals')) {
                    scope['elif.conditionals'] = [];
                }
                scope['elif.conditionals'].push(conditionals);
            },
            extend: function(scope, fn, callback) {
                var conditionals = getConditionals(scope);
                if (!conditionals) {
                    throw new Error('elif.extend: no if found at this level');
                }
                if (conditionals.fallthrough) {
                    throw new Error('elif.extend: else-if after else');
                }

                conditionals.push({
                    fn: fn,
                    callback: callback
                });
            },
            fallthrough: function(scope, fn, callback) {
                var conditionals = getConditionals(scope);
                if (!conditionals) {
                    throw new Error('elif.fallthrough: no if found at this level');
                }
                if (conditionals.fallthrough) {
                    throw new Error('elif.fallthrough: else already found at this level');
                }
                conditionals.fallthrough = callback;
            }
        };
    }]);

    // This implementation is basically the built-in `ng-if`, hooked into the `elif` service.
    var elifDirective = function(name, method, getter) {
        elif.directive(name, ['$animate', '$document', '$injector', 'elif', function($animate, $document, $injector, elif) {
            var getterFn = getter && $injector.invoke(getter);

            return {
                transclude: 'element',
                restrict: 'A',
                priorty: 600,
                terminal: true,
                link: function(scope, element, attrs, ctrls, transcludeFn) {
                    var watchFn = getterFn && getterFn(scope, element, attrs);
                    var childScope;
                    var childElement;
                    var previousElements;

                    elif[method](scope, watchFn, function(value, conditionals) {
                        if (value) {
                            if (!childScope) {
                                childScope = scope.$new();
                                transcludeFn(childScope, function(clone) {
                                    clone[clone.length + 1] = $document[0].createComment(' end ' + name + ': ' + attrs[name] + ' ');
                                    childElement = clone;
                                    $animate.enter(clone, element.parent(), element);
                                });
                            }
                        } else {
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }
                            if (previousElements) {
                                previousElements.remove();
                                previousElements = null;
                            }
                            if (childElement) {
                                previousElements = getBlockElements(childElement);
                                $animate.leave(previousElements, function() {
                                    previousElements = null;
                                });
                                childElement = null;
                            }
                        }
                    });
                }
            };
        }]);
    };

    // Reads the attribute given by `name` and converts it to a boolean.
    var getter = function(name) {
        return ['$parse', function($parse) {
            return function(scope, element, attrs) {
                var testFn = $parse(attrs[name]);
                return function() {
                    return !!testFn(scope);
                };
            };
        }];
    };

    // We rely on the built-in `ng-if` directive to actually perform
    // the transclusion, and simply tie it in to the `elif` service.
    elif.directive('ngIf', ['$injector', 'elif', function($injector, elif) {
        var getterFn = $injector.invoke(getter('ngIf'));
        return {
            priority: 600,
            link: function(scope, element, attrs) {
                var watchFn = getterFn(scope, element, attrs);
                elif.create(scope, watchFn);
            }
        }
    }]);

    // Else-if and else perform their own transclusions.
    elifDirective('ngElseIf', 'extend', getter('ngElseIf'));
    elifDirective('ngElif', 'extend', getter('ngElif'));

    // Else doesn't take an argument.
    elifDirective('ngElse', 'fallthrough');
})();

ayb.service('GTMService', GTMService);

GTMService.$inject = ['$window'];

function GTMService($window) {

    var dataLayer = $window.dataLayer = $window.dataLayer || [];

    function api(obj) {

        dataLayer.push(obj);

    }

    api.trackEvent = function(obj) {

        var attr = obj.attributes;
        var abort;

        angular.forEach(['category', 'action'], function(el) {

            var err;

            if (typeof attr[el] === 'undefined') {
                err = new Error('GTMService.trackEvent: Missing required property ' + el + '. Aborting hit.');
                throw err;
            }

        });

        dataLayer.push({
            'event': 'ngTrackEvent',
            'attributes': {
                'workshopId': attr.workshopId,
                'action': attr.action,
                'workshopName': attr.workshopName,
                'amount': attr.amount
            }
        });

    }

    return push;

}

ayb.filter('containsWord', function() {
    return function(items, attribute, word) {
        if (!word) {
            return items;
        }
        var filtered = [];
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            var attValue = item[attribute];
            if (attValue) {
                if (Array.isArray(attValue)) {
                    if (attValue.includes(word)) {
                        filtered.push(item);
                    }
                } else if (attValue.contains(word)) {
                    filtered.push(item);
                }
            }
        }
        return filtered;
    };
});

ayb.filter('unsafe', ['$sce', function($sce) {
    return function(input) {
        return $sce.trustAsHtml(input);
    }
}]);

ayb.filter('trustAsResourceUrl', ['$sce', function($sce) {
    return function(val) {
        return $sce.trustAsResourceUrl(val);
    }
}]);


ayb.config(['$locationProvider', function($locationProvider) {
    $locationProvider.html5Mode({
        enabled: false,
        requireBase: false
    });
}]);

angular.element(document).ready(function() {
    console.log('page loading completed');
});
