/**
 * helpers.js
 *
 * Sammlung von Hilfsfunktionen für AngularJS-Code oder allgemeine Webanwendungen.
 * Diese Funktionen werden als ES-Module exportiert und können per `import` in andere Dateien eingebunden werden.
 *
 * Verwendung:
 * import * as Helpers from '../../angularjs/helpers.js';
 * Helpers.createMetaPropertyIfNeeded('og:url', 'https://example.com');
 */

/**
 * Erzeugt dynamisch ein Meta-Tag mit gegebenem `property`-Attribut, falls es noch nicht existiert.
 * @param {string} property - Der Wert des property-Attributs (z. B. "og:title").
 * @param {string} content - Der Inhalt, der im Meta-Tag hinterlegt werden soll.
 */
export function createMetaPropertyIfNeeded(property, content) {
    if (!property || !content) return;
    let elem = document.querySelector(`meta[property='${property}']`);
    if (!elem) {
        elem = document.createElement('meta');
        elem.setAttribute('property', property);
        elem.setAttribute('content', content);
        document.head.appendChild(elem);
    }
}

/**
 * Parst einen Query-String aus einer URL und gibt ein Dictionary (Objekt) zurück.
 * Beispiel: "https://example.com?lang=de&testMode=true" -> { lang: "de", testMode: "true" }
 * @param {string} url - Die URL, aus der die Query-Parameter extrahiert werden.
 * @returns {object} - Ein Objekt mit Key-Value-Paaren der Query-Parameter.
 */
export function parseQueryStringToDictionary(url) {
    const result = {};
    if (typeof url !== 'string') return result;

    const queryIndex = url.indexOf("?");
    if (queryIndex < 0) return result;

    const query = url.substring(queryIndex + 1);
    const vars = query.split("&");

    for (const v of vars) {
        const pair = v.split("=");
        const key = decodeURIComponent(pair[0]);
        const val = decodeURIComponent(pair[1] || "");
        result[key] = val;
    }

    return result;
}

/**
 * Liefert einen lokalisierten Wert zurück. Ist `rootScope.isEnglish` wahr,
 * wird `englishValue` zurückgegeben, sonst `germanValue`.
 * Ist keiner der Werte vorhanden, wird ein Default-Wert verwendet.
 * @param {object} rootScope - Das $rootScope-Objekt von Angular, enthält die Sprachinfo (isEnglish).
 * @param {string} englishValue - Der englische Wert.
 * @param {string} germanValue - Der deutsche Wert.
 * @param {string} [defaultValue=""] - Ein optionaler Standardwert, falls beide Werte fehlen.
 * @returns {string}
 */
export function localizedValue(rootScope, englishValue, germanValue, defaultValue = "") {
    const useEnglish = rootScope.isEnglish && englishValue && englishValue.length > 0;
    if (!germanValue && !englishValue) return defaultValue;
    return useEnglish || !germanValue ? englishValue : germanValue;
}

/**
 * Sucht in einem Array von Objekten nach dem ersten Objekt,
 * dessen Wert für `key` dem Parameter `value` entspricht.
 * @param {Array} array - Das Array von Objekten.
 * @param {string} key - Der Schlüssel, nach dem gesucht wird.
 * @param {any} value - Der zu suchende Wert.
 * @returns {object|null} - Das gefundene Objekt oder null, falls keines passt.
 */
export function findObjectByKey(array, key, value) {
    if (!Array.isArray(array) || !key) return null;
    return array.find(item => item[key] === value) || null;
}

/**
 * Liefert einen Text aus einem mehrsprachigen Descriptions-Array,
 * basierend auf dem angegebenen Sprachcode (z. B. 'DE' oder 'EN').
 * @param {Array} descriptions - Array von Objekten mit {language: 'EN'|'DE', text: string oder content: string}
 * @param {string} lang - Die gewünschte Sprache ('EN', 'DE', ...)
 * @returns {string} - Der gefundene Text oder ein leerer String.
 */
export function textFromDescriptionsDict(descriptions, lang) {
    if (!Array.isArray(descriptions) || !lang) return "";
    const descriptionObject = findObjectByKey(descriptions, "language", lang);
    let text = descriptionObject ? (descriptionObject.text || descriptionObject.content || "") : "";
    return fixedFitogramDescriptionText(text);
}

/**
 * Bereinigt einen aus Fitogram stammenden Beschreibungstext:
 * - Entfernt den Teil nach '--- snip ---'
 * - Entfernt überflüssige HTML-Tags wie <p>&nbsp;</p>
 * @param {string} text - Der zu bereinigende Text.
 * @returns {string} - Der bereinigte Text.
 */
export function fixedFitogramDescriptionText(text) {
    if (typeof text !== 'string') return "";

    const indexOfSnip = text.indexOf('--- snip ---');
    if (indexOfSnip > 0) {
        text = text.substring(0, indexOfSnip);
    }

    return text
        .replaceAll("<p>&nbsp;</p>", "")
        .replaceAll("<p>&nbsp;", "")
        .trim();
}
