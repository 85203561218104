angular.
module('location').
component('location', {
    bindings: {
        locationId: '<',
        location: '<',
        title: '<'
    },
    templateUrl: '/angularjs/components/location/location.template.html',
    controller: function($rootScope) {
        var self = this;

        self.$onInit = function() {
            console.log("on init in location");
            if (!self.title) {
                if (self.location) {
                    self.title = self.location.name;
                } else {

                    if (self.locationId == "ayb") {
                        self.title = "Ashtanga Yoga Berlin | Mitte/Prenzlauer Berg";
                    } else {
                        if (self.locationId == "muehle") {
                            self.title = "Breitenteicher Mühle";
                            return self.title;
                        }
                    }
                    if (!self.title) {
                        self.title = self.locationId;
                    }
                }
            }
        }
    }
});
