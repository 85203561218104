angular.
  module('date').
  component('date', {	
 	bindings: {
	  value: '<',
	  format: '<',
	  f: '<',
	  year: '<',
	  style: '@',
	  class: '@'
	},
	template: '<span class="dateMultilang" style = "[[ $ctrl.style ]]" class = "[[ $ctrl.class ]]" data-date = "[[ $ctrl.dateMoment() ]]">[[ $ctrl.dateString() ]]</span>',
    controller: function($element, $rootScope) { 
    	/* $scope, $element, $attrs, $transclude */
		/*$transclude(function(clone, scope) {
                //console.log(clone.text(), scope.hello);
                if (clone.text()) {
                	scope.$$prevSibling.transValue = clone.text();
                }
                //transcludedContent = clone;
                //transclusionScope = scope;
            });	*/
		this.dateMoment = function() {
			if (!this._dateMoment) {
			    this._dateMoment = momentForDateString(this.value);
			}
			return this._dateMoment;
		}
		this.dateString = function() {
			var momentvar = this.dateMoment();
			if (momentvar != null) {
				var format = this.format;
				if (!format) {
				var formatHint = this.f;
					switch(formatHint) {
						case 'l':
							format = dateFormatL;		
							break;
						case 'm':
							format = dateFormatM;
							break;
						case 's':
							format = dateFormatS;
							break;	
						case 'xs':
							format = dateFormatXS;					
							break;
						default:
							format = dateFormatDefault;					
					}
				}
				if (this.year) {
					format = format + ' YY';
				}
				return momentvar.locale($rootScope.lang).format(format);
			}
			return "undefined date";
		}	
	}

});